.listing-description-html {
  line-height: 24px;

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ol,
  ul {
    padding-inline-start: 32px;

    li {
      list-style: inherit;
    }
  }
}
