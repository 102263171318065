/* always keep transition durations in lockstep with closeTimeoutMS in SlidingPane */

.sliding-pane-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 160;

  opacity: 0;
  transition: opacity 700ms ease-in-out;
}

.sliding-pane-overlay--after-open {
  opacity: 1;
}

.sliding-pane-overlay--before-close {
  opacity: 0;
}

.sliding-pane-modal-regular,
.sliding-pane-modal-large {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: unset;
  border-radius: 0px;
  border: none;
  background: #fff;
  outline: none;
  box-shadow: -8px 0 16px rgba(4, 31, 71, 0.04);

  right: -100%;
  transition: right 700ms ease-in-out;
}

.sliding-pane-modal-regular {
  width: 750px;
}

.sliding-pane-modal-large {
  width: 950px;
}

.sliding-pane-modal--after-open {
  right: 0;
}

.sliding-pane-modal--before-close {
  right: -100%;
}

.sliding-pane-modal-body--open,
.sliding-pane-modal-html--open {
  overflow: hidden;
}
